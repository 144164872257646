import { createComponent, createComponentWithAdditionalModules, createComponentWithPathAndName } from "./utils";

export default function createRoutes(store) {
	return [
		{
			path: "/projects/overview",
			exact: true,
			component: createComponent(store, "projects/overview"),
			needLayout: true,
			accessPermissions: ["Projects_Page"],
		},
		{
			path: "/customer-projects/overview",
			exact: true,
			component: createComponent(store, "projects/customer-overview"),
			needLayout: true,
			accessPermissions: ["Customer_Projects_Page"],
		},
		{
			path: "/projects/create",
			exact: true,
			component: createComponentWithPathAndName(store, "projects/project/create", "projects/project"),
			needLayout: true,
			accessPermissions: ["Project_Create_Page"],
		},
		{
			path: "/projects/:id/edit",
			exact: true,
			component: createComponentWithPathAndName(store, "projects/project/edit", "projects/project"),
			needLayout: true,
			accessPermissions: ["Project_Edit_Page"],
		},
		{
			path: "/projects/:id/edit-pol",
			exact: true,
			component: createComponentWithPathAndName(store, "projects/project/edit-pol", "projects/project"),
			needLayout: true,
			accessPermissions: ["Project_Edit_Page"],
		},
		{
			path: "/projects/:id/view",
			exact: true,
			component: createComponentWithPathAndName(store, "projects/project/view", "projects/project"),
			needLayout: true,
			accessPermissions: ["Project_View_Page"],
		},
		{
			path: "/projects/:idProject/sub-projects/create",
			exact: true,
			component: createComponent(store, "projects/sub-project"),
			needLayout: true,
			accessPermissions: ["Project_Create_Page"],
		},
		{
			path: "/projects/:idProject/sub-projects/:id/create",
			exact: true,
			component: createComponent(store, "projects/sub-project"),
			needLayout: true,
			accessPermissions: ["Project_Create_Page"],
		},
		{
			path: "/projects/:idProject/sub-projects/:id/view",
			exact: true,
			component: createComponent(store, "projects/sub-project"),
			needLayout: true,
			accessPermissions: ["Project_View_Page"],
		},
		{
			path: "/view-attachment",
			exact: true,
			component: createComponent(store, "view-attachment"),
			needLayout: true,
		},
		{
			path: "/projects/:id/punch-list/create-external",
			exact: true,
			component: createComponent(store, "external-punch"),
			needLayout: false,
			externalForm: true,
			//accessPermissions: [],
		},
		{
			path: "/projects/:id/punch-list/create-external",
			exact: true,
			component: createComponent(store, "external-punch"),
			needLayout: true,
			externalForm: false,
			//accessPermissions: [],
		},
		{
			path: "/projects/:id",
			exact: false,
			component: createComponentWithPathAndName(store, "projects", "currentProject"),
			needLayout: true,
			//accessPermissions: ["Projects_Page"],
		},
		{
			path: "/my-tasks",
			exact: true,
			component: createComponent(store, "my-tasks"),
			//accessPermissions: [],
			needLayout: true,
		},
		{
			path: "/dashboard",
			exact: true,
			component: createComponent(store, "dashboard"),
			accessPermissions: ["MyTasks_Page"],
			needLayout: true,
		},
		{
			path: "/release-notes",
			exact: true,
			component: createComponent(store, "release-notes"),
			accessPermissions: ["Release_Notes_Page"],
			needLayout: true,
		},
		{
			path: "/user-management",
			exact: true,
			component: createComponent(store, "user-management"),
			accessPermissions: ["User_Management_Page"],
			needLayout: true,
		},
		{
			path: "/ges-online",
			exact: true,
			component: createComponent(store, "catalog"),
			accessPermissions: ["GES_Online_Page"],
			needLayout: true,
		},
		{
			path: "/admin-portal-navigator",
			exact: true,
			component: createComponent(store, "admin-portal-navigator"),
			accessPermissions: ["Portal_Navigator_Administrator_Page"],
			needLayout: true,
		},
		{
			path: "/jungle-navigator",
			exact: true,
			component: createComponent(store, "portal-navigator"),
			accessPermissions: ["Jungle_Navigator_Page"],
			needLayout: true,
		},
		{
			path: "/carousel-management",
			exact: true,
			component: createComponent(store, "carousel-management"),
			accessPermissions: ["Carousel_Management_Page"],
			needLayout: true,
		},
		{
			path: "/role-management",
			exact: true,
			component: createComponent(store, "role-permission-management"),
			needLayout: true,
			accessPermissions: ["Role_Write", "Role_Management_Page"],
		},
		{
			path: "/login",
			exact: true,
			component: createComponent(store, "login"),
			needLayout: false,
		},
		{
			path: "/logout",
			exact: true,
			component: createComponent(store, "logout"),
			needLayout: false,
		},
		{
			path: "/stream",
			exact: true,
			component: createComponent(store, "stream"),
			needLayout: true,
			accessPermissions: ["Videos_Page"],
		},
		{
			path: "/lessons-learned",
			needLayout: true,
			exact: true,
			component: createComponent(store, "lessons-learned"),
		},

		// {
		// 	path: "/404",
		// 	exact: true,
		// 	component: createComponent(store, "404"),
		// 	needLayout: false,
		// },
		{
			path: "/403",
			exact: true,
			component: createComponent(store, "403"),
			needLayout: false,
		},
		{
			path: "/500",
			exact: true,
			component: createComponent(store, "500"),
			needLayout: false,
		},
		{
			path: "*",
			exact: false,
			component: createComponent(store, "404"),
			needLayout: false,
		},
	];
}
