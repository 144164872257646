import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory, matchPath } from "react-router-dom";
import { push } from "connected-react-router";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import LeftMenu from "containers/left-menu";
import { LeftMenuContext } from "containers/left-menu/context";

import MenuItems from "containers/menu-items";
import AuthorizedRoute from "../authorized-route";
import backgroundImg from "../../assets/images/bg.jpg";
import WelcomeScreen from "./welcome-screen-container";
import WebChat from "./web-chat-container";
import Notifications from "./notification-container";
import TopBar from "./top-bar-container";
import usePreloader from "../../components/preloader/component";
import NewsBulletin from "./news-bulletin/component";
import BackdropLoader from "../../components/common/backdrop-loader";
import { cancelPreviousPageRequests } from "services/api-service/axios-instance";

import { selectIsLoggedIn, getUserAvatar, getUserDetails, selectCarouselContent, selectShowCarousel } from "./selectors";
import { getCurrentUserDetails, getCarouselContent } from "./actions";
let previousPath = "";
let currentPath = "";

const Layout = ({ routes, ...props }) => {
	const dispatch = useDispatch();
	const [preloader, showPreloader] = usePreloader();

	let isLoggedIn = useSelector(selectIsLoggedIn);
	let currentUser = useSelector(getUserDetails);
	let carouselContent = useSelector(selectCarouselContent);
	let showCarousel = useSelector(selectShowCarousel);
	//const userAvatar = useSelector(getUserAvatar);
	const history = useHistory();

	const [initialLocation, setInitialLocation] = useState({
		pathname: "/my-tasks",
	});

	useEffect(() => {
		if (!previousPath && !currentPath) {
			previousPath = history.location.pathname;
			currentPath = history.location.pathname;
		} else {
			previousPath = currentPath;
			currentPath = history.location.pathname;
		}

		if (previousPath !== currentPath && !previousPath.includes("login")) {
			cancelPreviousPageRequests(previousPath);
		}
	}, [history.location?.pathname]);

	const matchRoute = () =>
		routes.find(r =>
			matchPath(history.location.pathname, {
				path: r.path,
				exact: r.exact,
			})
		);

	const useStyles = showBackground =>
		makeStyles(theme => ({
			loginBG: {
				background: showBackground ? `url(${backgroundImg})` : null,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				display: "flex",
				height: "100%",
				paddingTop: "64px",
			},
		}));

	const classes = useStyles(history.location.pathname === "/login")();

	const displayLayout = () => {
		return matchRoute()?.needLayout;
	};

	useEffect(() => {
		if (history.location.pathname !== "/" && history.location.pathname !== "/login" && history.location.pathname !== "/logout") {
			setInitialLocation(history.location);
		}
	}, []);

	useEffect(() => {
		if (!isLoggedIn && matchRoute()?.externalForm) return;

		if (!isLoggedIn) {
			if (!history.location.pathname.includes("/login")) dispatch(push("/login"));
		} else {
			dispatch(getCurrentUserDetails());
			dispatch(getCarouselContent());
			if (initialLocation.pathname !== "/my-tasks") {
				setTimeout(() => {
					history.push(initialLocation);
					setInitialLocation({ pathname: "/my-tasks" });
				}, 500);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn]);

	// useEffect(() => {
	// 	dispatch(getNotificationFlag());
	// }, []);

	useEffect(() => {
		if (!currentUser?.permissions && isLoggedIn) showPreloader(true);
		else showPreloader(false);
	}, [currentUser, isLoggedIn]);

	const [leftMenuContext, setLeftMenuContext] = useState([]);

	useEffect(() => {
		if (!leftMenuContext?.length && currentUser.hasOwnProperty("authenticationType")) {
			let menuItems = MenuItems(currentUser);
			setLeftMenuContext(menuItems);
		}
	}, [leftMenuContext, currentUser]);

	return (
		<>
			{!currentUser?.permissions && isLoggedIn && (
				<div
					style={{
						position: "relative",
						width: "100%",
						height: "100%",
					}}>
					{preloader}
				</div>
			)}
			<LeftMenuContext.Provider value={[leftMenuContext, setLeftMenuContext]}>
				<div className={classes.loginBG}>
					<Notifications />
					{displayLayout() ? <TopBar /> : null}
					{displayLayout() ? <LeftMenu /> : null}
					<Grid
						container
						style={{
							padding: "20px",
							paddingBottom: "70px",
							overflow: "auto",
							flexGrow: 1,
						}}>
						<Grid item xs={12}>
							<Suspense fallback={<BackdropLoader />}>
								<Switch>
									{isLoggedIn && currentUser?.permissions
										? routes
											.filter(route => route.needLayout)
											.map((route, key) => (
												<AuthorizedRoute
													key={key}
													accessPermissions={route.accessPermissions}
													exact={route.exact}
													path={route.path}
													component={route.component}
												/>
											))
										: null}

									<Route exact={true} path={["/"]}>
										<Redirect to="/my-tasks" />
									</Route>

									{routes
										.filter(route => !route.needLayout)
										.map((route, key) => (
											<AuthorizedRoute
												key={key}
												exact={route.exact}
												accessPermissions={route.accessPermissions}
												path={route.path}
												component={route.component}
											/>
										))}
								</Switch>
							</Suspense>
						</Grid>
					</Grid>

					{/* Render carousel and webchat only if the user has yokogawa default role */}
					{displayLayout() && currentUser?.permissions && currentUser?.permissions?.includes("Projects_Page") && <WebChat />}
					{displayLayout() && showCarousel && currentUser?.permissions && currentUser?.permissions?.includes("Projects_Page") && (
						<NewsBulletin items={carouselContent} {...props} />
					)}

					<WelcomeScreen></WelcomeScreen>
				</div>
			</LeftMenuContext.Provider>
		</>
	);
};

export default Layout;
