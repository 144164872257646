import httpBase, { generateNewAccessToken } from "./api-service/base-service";
import { ACCOUNT_URI, USER_PROFILE_SERVICE_URI } from "./api-service/constants";
import odataFilter from "odata-filter-builder";
import buildQuery from "odata-query";

const UserService = {
	saveUserDefaultOrganization: organization => httpBase.post(`${ACCOUNT_URI}/Set-User-Organization`, { organization }).then(() => generateNewAccessToken()),
	getCurrentUserDetails: () => httpBase.get(`${ACCOUNT_URI}/me`),
	getUserDataPermissions: user => httpBase.get(`${ACCOUNT_URI}/${user}/data-permissions`),
	getUsers: displayNameFilter =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/users/filterBy-name-email${buildQuery({
				select: ["id", "uid", "displayName", "email", "hasCustomerRole", "company"],
				filter: odataFilter()
					// .contains(x => x.toLower("displayName"), displayNameFilter.toLowerCase())
					// .or(x => x.contains(x => x.toLower("email"), displayNameFilter.toLowerCase()))
					.eq("hasCustomerRole ", false)
					.toString(),
			})}&searchString=${displayNameFilter}`
		),
	getUsersAvatarForPunchList: (displayNameFilter, projectCustomers = []) =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/users/filterBy-name-email${buildQuery({
				select: ["id", "uid", "displayName", "email", "avatar", "hasCustomerRole", "company"],
				filter: projectCustomers.length
					? odataFilter()
						// .contains(x => x.toLower("displayName"), displayNameFilter.toLowerCase())
						// .or(x => x.contains(x => x.toLower("email"), displayNameFilter.toLowerCase()))
						.eq("hasCustomerRole", false)
						.or(x =>
							x
								// .contains(x => x.toLower("displayName"), displayNameFilter.toLowerCase())
								// .or(x => x.contains(x => x.toLower("email"), displayNameFilter.toLowerCase()))
								.in("uid", projectCustomers, false)
								.eq("hasCustomerRole", true)
						)
						.toString()
					: odataFilter()
						// .contains(x => x.toLower("displayName"), displayNameFilter.toLowerCase())
						// .or(x => x.contains(x => x.toLower("email"), displayNameFilter.toLowerCase()))
						.eq("hasCustomerRole", false)
						.toString(),
			})}&searchString=${displayNameFilter}`
		),
	getUsersWithAvatar: displayNameFilter =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/users/filterBy-name-email${buildQuery({
				select: ["id", "uid", "displayName", "email", "avatar", "hasCustomerRole", "company"],
				filter: odataFilter().eq("hasCustomerRole ", false).toString(),
			})}&searchString=${displayNameFilter}`
		),

	getUsersWithAvatarAndRoles: displayNameFilter =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/users/filterBy-name-email${buildQuery({
				select: ["id", "uid", "displayName", "email", "avatar", "hasCustomerRole", "company", "userRoles"],
				filter: odataFilter().eq("hasCustomerRole ", false).toString(),
			})}&$expand=userRoles&searchString=${displayNameFilter}`
		),

	getCustomersWithAvatar: displayNameFilter =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/users/filterBy-name-email${buildQuery({
				select: ["id", "uid", "displayName", "email", "avatar", "hasCustomerRole", "jobTitle", "company"],
				filter: odataFilter()
					.eq("hasCustomerRole ", true)
					.toString(),
			})}&searchString=${displayNameFilter}`
		),
	getCustomersAndQaWithAvatar: (displayNameFilter, organization) =>
			httpBase.get(
				`${USER_PROFILE_SERVICE_URI}/users/filterBy-name-email?
											$select=id,uid,displayName,email,avatar,hasCustomerRole,jobTitle,company
											&$filter=hasCustomerRole eq true or userRoles/any(role: role/organization eq ${organization} and role/roleType eq 4)
											&searchString=${displayNameFilter}`
			),
	uploadUserAvatar: avatar => httpBase.post(`${ACCOUNT_URI}/update-avatar`, { picture: avatar }),
	getRoles: () =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/roles${buildQuery({
				select: ["uid", "name", "isProjectManager", "isCustomerRole"],
				filter: odataFilter()
					.eq("IsProjectRole", true)
					.or(x => x.eq("IsServiceRole", true))
					.toString(),
			})}`
		),
	getAllRoles: () =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/roles${buildQuery({
				select: ["name", "id", "uid"],
			})}`
		),
	getRolesForAdminPage: () =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/roles${buildQuery({
				select: ["name", "id", "uid", "color", "label"],
				filter: odataFilter().eq("isCustomerRole", false).toString(),
			})}`
		),
	getUsersAvatar: users => {
		let uniqueUsers = [...new Set(users)];
		let usersQuery = uniqueUsers.map(u => `users=${u}`).join("&");
		return httpBase.get(`${USER_PROFILE_SERVICE_URI}/users/filterBy-uid?${usersQuery}&$select=uid,email,avatar`);
	},
	getUserAvatar: uids => {
		let usersQuery = uids.map(u => `users=${u}`).join("&");
		return httpBase.get(`${USER_PROFILE_SERVICE_URI}/users/filterBy-uid?${usersQuery}&$select=uid, avatar`);
	},
	createUserRole: user => httpBase.post(`${USER_PROFILE_SERVICE_URI}/userRoles`, user),
	get: (query = "") => httpBase.get(`${USER_PROFILE_SERVICE_URI}/users${query}`),
	getManage: (query = "") => httpBase.get(`${USER_PROFILE_SERVICE_URI}/users/manage${query}`),
	createUserRoles: userRoles => httpBase.post(`${USER_PROFILE_SERVICE_URI}/userRoles/user-roles`, userRoles),

	getUserRoles: user => {
		const query = `&$select=userRoles&$expand=userRoles($select=role,organization)`;
		return httpBase.get(`${USER_PROFILE_SERVICE_URI}/users/filterBy-uid?users=${user}${query}`);
	},
	getCustomerCompanyById: uid => {
		const query = `&$select=company`;
		return httpBase.get(`${USER_PROFILE_SERVICE_URI}/users/filterBy-uid?users=${uid}${query}`);
	},
	getUsersByUids: (uids, select) => {
		let usersQuery = uids.map(u => `users=${u}`).join("&");
		return httpBase.get(`${USER_PROFILE_SERVICE_URI}/users/filterBy-uid?${usersQuery}&$select=${select}&$expand=userRoles`);
	},
	getBaseDetails: users => {
		let uniqueUsers = [...new Set(users)];
		let usersQuery = uniqueUsers.map(u => `users=${u}`).join("&");
		return httpBase.get(`${USER_PROFILE_SERVICE_URI}/users/filterBy-uid?${usersQuery}&$select=uid,email,company,displayName,hasCustomerRole`);
	},
	getCustomersRoles: () =>
		httpBase.get(
			`${USER_PROFILE_SERVICE_URI}/roles${buildQuery({
				select: ["uid", "type"],
				filter: odataFilter().eq("isCustomerRole", true).toString(),
			})}`
		),
	deleteUserOrganization: (organization, user) => {
		return httpBase.delete(`${USER_PROFILE_SERVICE_URI}/users/${user}/organizations/${organization}`);
	},
	updateNotificationServices: notificationServices => {
		return httpBase.patch(`${USER_PROFILE_SERVICE_URI}/users`, notificationServices);
	}
};
export default UserService;
