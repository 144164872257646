export default currentUser => [
	{
		text: "Help Desk",
		icon: "helpdesk",
		isImplemented: true,
		isNew: false,
		isFooter: true,
		accessPermissions: ["HelpDesk_Page"],
		children: [
			{
				text: "Service Now",
				path: "https://yokogawa.service-now.com/",
				isImplemented: true,
				isNew: false,
			},
			{
				text: "Community",
				path: "https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMzc4OTk4MzUzOTIifQ",
				isImplemented: true,
				isNew: false,
			},
			{
				text: "Contact Us",
				path: "mailto:support_myproject@ml.jp.yokogawa.com?subject=Helpdesk",
				isImplemented: true,
				isNew: false,
			},
		],
	},
	{
		text: "Videos",
		path: "https://ykgwoffice.sharepoint.com/sites/myProjectReleaseNotes/Lists/myProject%20Playlist/AllItems.aspx",
		icon: "stream",
		isImplemented: true,
		accessPermissions: ["Videos_Page"],
		isNew: false,
		isFooter: true,
	},
	{
		text: "Release Notes",
		path: currentUser?.authenticationType?.toLowerCase() === "sso_account" ? "/release-notes" : "https://ykgwoffice.sharepoint.com/sites/myProjectReleaseNotes",
		icon: "news",
		isImplemented: true,
		accessPermissions: ["Release_Notes_Page"],
		isNew: false,
		isFooter: true,
	},
];