const iconsFont = "Yoko-Icons";

export default theme => ({
	delete: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e907"',
		},
	},
	edit: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e900"',
		},
	},
	view: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e902"',
		},
	},
	deactivate: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e903"',
		},
	},
	duplicate: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e904"',
		},
	},
	auditTrail: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e905"',
		},
	},
	add: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e906"',
		},
	},
	subMenu: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e908"',
		},
	},
	configure: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e909"',
		},
	},
	activate: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e90a"',
		},
	},
	catalog: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e90c"',
		},
	},
	checklist: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e901"',
		},
	},
	checkBoxUnchecked: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e90d"',
		},
	},
	CheckBoxChecked: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e90b"',
		},
	},
	checkBoxIndeterminate: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e93a"',
		},
	},
	dashboard: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e910"',
		},
	},
	projects: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e90f"',
		},
	},
	organization_overview: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e90e"',
		},
	},
	project_team_manager: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e919"',
		},
	},
	project_cloud_desk: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e913"',
		},
	},
	project_documents: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e914"',
		},
	},
	project_e_library: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e915"',
		},
	},
	project_quality_tracker: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e917"',
		},
	},
	project_smart_assist: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e918"',
		},
	},
	project_punch_list: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e91c"',
		},
	},
	Icon_Info: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e920"',
		},
	},
	Arrow_Icon: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e911"',
		},
	},
	Icon_Dropdown: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e912"',
		},
	},
	Reset: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e91f"',
		},
	},
	export: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e921"',
		},
	},
	work_list: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e922"',
		},
	},
	stream: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e923"',
		},
	},
	icon_log: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e91d"',
		},
	},
	feedback: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e91b"',
		},
	},
	copy: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e924"',
		},
	},
	helpdesk: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e925"',
		},
	},
	add_subproject: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e926"',
		},
	},
	has_feedback: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e927"',
		},
	},
	icon_notification_flag: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e928"',
		},
	},
	favorite: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e916"',
		},
	},
	portalNav: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e92b"',
		},
	},
	Icon_Profile: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e92c"',
		},
	},
	Icon_Upload: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e92d"',
		},
	},
	Jungle_Navigator: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e92e"',
		},
	},
	Icon_Filter: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e92f"',
		},
	},
	Icon_Warning: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e930"',
		},
	},
	Icon_Lessons: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e931"',
		},
	},
	news: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e932"',
		},
	},
	Icon_Likes: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e933"',
		},
	},
	Icon_Likes_outline: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e934"',
		},
	},
	Icon_Views: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e935"',
		},
	},
	Icon_Cards: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e936"',
		},
	},
	Icon_Table: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e937"',
		},
	},
	Icon_Power: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e938"',
		},
	},
	Icon_VMReset: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e939"',
		},
	},
	Icon_Email: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e93b"',
		},
	},
	Icon_Favorites: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e93c"',
		},
	},
	Icon_Close: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e93d"',
		},
	},
	Icon_POL: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e93e"',
		},
	},
	Icon_MyTasks: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e940"',
		},
	},
	Icon_Tasks_Reviews: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e941"',
		},
	},
	Folder: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e942"',
		},
	},
	Field_Folder: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e943"',
		},
	},
	Subproject: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e944"',
		},
	},
	Remove: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e945"',
		},
	},
	Overdue: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e946"',
		},
	},
	OverdueAction: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e947"',
		},
	},
	lock: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e948"',
		},
	},
	rdp: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e949"',
		},
	},
	tq_Icon: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e950"',
		},
	},
	closeOut: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e94a"',
		},
	},
	project_digital_test_procedure: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e952"',
		},
	},
	project_digital_test_procedure_fail: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e953"',
		},
	},
	project_digital_test_procedure_in_progress: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e954"',
		},
	},
	project_digital_test_procedure_pass: {
		fontFamily: iconsFont,
		"&:before": {
			content: '"\\e955"',
		},
	},
});
